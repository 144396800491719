<template>
  <div class="d-flex align-items-center justify-content-around w-100 bg-dark" style="height: calc(100vh - 3.375rem)">
    <div class="text-center">
      <hr style="border-color: #AC8D64;">
      <h1 class="text-center fw-bold font-weight-bold mb-0" style="color: #AC8D64; font-size: 5rem">FéCaffé</h1>
      <hr style="border-color: #AC8D64;">
      <address style="color: #AC8D64; font-size: 1.2rem">
        10 Oxton Road<br>
        Birkenhead<br>
        Wirral<br>
        CH41 2QJ
      </address>
      <hr style="border-color: #AC8D64;">
      <p style="color: #AC8D64; text-transform: uppercase;" class="mb-0">
        tel: <a class="mb-0" style="font-size: 1.2rem" href="tel:+447777564366">07777 564366</a>
      </p>
      <hr style="border-color: #AC8D64;">
      <div class="d-flex align-items-center justify-content-around">
        <div>
          <a style="color: #AC8D64; font-size: 1.2rem" href="https://www.instagram.com/fecaffe7" target="_blank">
            <fa-icon style="color: #AC8D64" :icon="['fab', 'instagram']" class="fa-2x" />
          </a>
        </div>
        <div>
          <a style="color: #AC8D64; font-size: 1.2rem" href="https://www.ubereats.com/gb/store/fe-caffe/sYH4MbCyTjSmfjqzwn-kSA" target="_blank">
            <fa-icon style="color: #AC8D64" :icon="['fab', 'uber']" class="fa-2x" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
